import { createRouter, createWebHistory } from "vue-router";

const routes = [
  { 
    path: '/', 
    name: 'home', 
    component: () => import('@/views/HomeView.vue')
  },
  { 
    path: '/about', 
    name: 'about', 
    component: () => import('@/views/AboutView.vue')
  },
  {
    path: '/services/:serviceType?', // Dynamic segment
    name: 'services',
    component: () => import('@/views/ServicesView.vue'),
    props: true
  },
  {
    path: '/education/:educationType?', // Dynamic segment
    name: 'education',
    component: () => import('@/views/EducationView.vue'),
    props: true
  },
  {
    path: '/products/',
    name: 'products',
    component: () => import('@/views/ProductsView.vue')
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
