<template>
    <div>

    </div>
</template>

<script>
export default{
    props:{
        name: String
    },
    created(){
        this.setMetaData();
    },
    watch: {
        '$route'() {
            // Change metadata
            this.setMetaData();
        }
    },
    methods:{
        setMetaData() {
            const page = this.$route.name;

            this.removeOldMetaTags(); // Удаляем старые мета-теги

            let metaDescription = document.createElement('meta');
            metaDescription.name = 'description';
            metaDescription.content = this.metaDescription(page);
            document.head.appendChild(metaDescription);

            let metaKeywords = document.createElement('meta');
            metaKeywords.name = 'keywords';
            metaKeywords.content = this.metaKeywords(page);
            document.head.appendChild(metaKeywords);
        },
        removeOldMetaTags() {
            const head = document.head;
            const metaTags = head.querySelectorAll('meta[name="description"], meta[name="keywords"]');
            metaTags.forEach(metaTag => head.removeChild(metaTag));
        },
        metaDescription(page) {
            const metaPage = this.$store.state.metaData[page] || this.$store.state.metaData.home;
            if(metaPage){
                const enMeta = metaPage.en.description || '';
                const amMeta = metaPage.am.description || '';
                return `${enMeta} | ${amMeta}`;
            }else{
                return ''
            }
        },
        metaKeywords(page) {
            const metaPage = this.$store.state.metaData[page] || this.$store.state.metaData.home;
            if(metaPage){
                const enMeta = metaPage.en.keywords || '';
                const amMeta = metaPage.am.keywords || '';
                return `${enMeta} | ${amMeta}`;
            }else{
                return ''
            }
        }
    }
}
</script>

<style scoped>
div{
    position: absolute;
}
</style>