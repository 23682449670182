import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// Импортируем функцию для скроллинга
import { scrollToBottom } from './utils/scrollHelper';

const app = createApp(App);

// Добавляем метод в глобальное пространство Vue
app.config.globalProperties.$scrollToBottom = scrollToBottom;

// Инициализируем store и router, монтируем приложение
app.use(store).use(router).mount('#app');