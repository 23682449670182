import { createStore } from 'vuex'

export default createStore({
  state: {
    metaData:null,
    pages: {},
    active_page: 'home',
    color: '#009961',
    scroll_position: 0,
    services: [],
    products: [],
    team: [],
    statistics: {},
    contacts: {},
    education: {}
  },
  getters: {
    // Геттеры для получения данных из состояния
  },
  mutations: {
    setActivePage(state, page) {
      // Мутация для изменения active_page
      state.active_page = page;
    },
    setServices(state, data){
      state.services = data;
    },
    setProducts(state, data){
      state.products = data;
    },
    setTeam(state, data){
      state.team = data;
    },
    setStatistics(state, data){
      state.statistics = data;
    },
    setContacts(state, data){
      state.contacts = data;
    },
    setEducation(state, data){
      state.education = data;
    },
    setScrollPosition(state, data){
      state.scroll_position = data;
    },
    setPages(state, data){
      state.pages = data;
    },
    setMetaData(state, data){
      state.metaData = data;
    }
  },
  actions: {
    changeActivePage({ commit }, page) {
      // Действие для изменения active_page (если нужно выполнить асинхронные операции)
      commit('setActivePage', page);
    },  
  },
  modules: {
    // Модули Vuex, если они есть
  }
})