<template>
    <header
        :style="{
            backgroundColor: window_position_is_0 ? 'rgba(40, 40, 40, 0)' : 'rgba(40, 40, 40, 0.7)'
        }"
    >
        <h1 class="logo">Logo</h1>

        <nav 
            class="header_buttons_box"
            :style="{
                height: nav_height,
                padding: nav_padding
            }"
        >
            <button class="page_button"
                @click="changePage('/')"
                :style="{opacity: $store.state.active_page === 'home' ? 1 : 0.8}"
            >Home
                <div class="is_active"
                    :style="{
                        opacity: $store.state.active_page === 'home' ? 1 : 0,
                        backgroundColor: $store.state.color
                    }">
                </div>
            </button>
            <button class="page_button"
                @click="changePage('/about')"
                :style="{opacity: $store.state.active_page === 'about' ? 1 : 0.8}"
            >
                <div class="is_active"
                    :style="{
                        opacity: $store.state.active_page === 'about' ? 1 : 0,
                        backgroundColor: $store.state.color
                    }">
                </div>
                About
            </button>
            <button class="page_button"
                @click="changePage('/services/')"
                :style="{opacity: $store.state.active_page === 'services' ? 1 : 0.8}"
            >
                <div class="is_active"
                    :style="{
                        opacity: $store.state.active_page === 'services' ? 1 : 0,
                        backgroundColor: $store.state.color
                    }">
                </div>
                Services
            </button>
            <button class="page_button"
                @click="changePage('/education/')"
                :style="{opacity: $store.state.active_page === 'education' ? 1 : 0.8}"
            >
                <div class="is_active"
                    :style="{
                        opacity: $store.state.active_page === 'education' ? 1 : 0,
                        backgroundColor: $store.state.color
                    }">
                </div>
                Education
            </button>
            <button class="page_button"
                @click="changePage('/products/')"
                :style="{opacity: $store.state.active_page === 'products' ? 1 : 0.8}"
            >
                <div class="is_active"
                    :style="{
                        opacity: $store.state.active_page === 'products' ? 1 : 0,
                        backgroundColor: $store.state.color
                    }">
                </div>
                Products
            </button>
            <!--
            <button class="page_button"
                @click="changePage('/statistics')"
                :style="{opacity: $store.state.active_page === 'statistics' ? 1 : 0.8}"
            >
                <div class="is_active"
                    :style="{
                        opacity: $store.state.active_page === 'statistics' ? 1 : 0,
                        backgroundColor: $store.state.color
                    }">
                </div>
                Statistics
            </button>
            -->
            
        </nav>

        <button class="page_button contact_us"
            @click="$scrollToBottom()"
            :style="{
                backgroundColor: $store.state.color
            }"
        >
            Contact Us
            <span>&#10132;</span>
        </button>


        <button 
            class="burger-bar"
            @click="burgerBarHandler()"
        >
            <img src="@/assets/burger-bar.png" alt="Burger bar icon">
        </button>
    </header>
</template>

<script>
export default {
    name: 'HeroSection',
    data() {
        return {
            window_position_is_0: true,
            nav_is_active: false,
            nav_height: 'unset',
            nav_padding: 'unset'
        }
    },
    mounted(){
        this.handleScroll();
        this.setNavBarStyles();
        window.addEventListener('resize', this.setNavBarStyles);
    },
    beforeUnmount(){
        window.removeEventListener('resize', this.setNavBarStyles);
    },
    watch: {
        '$store.state.scroll_position': {
            handler() {
                this.handleScroll();
            },
        },
        '$route'() {
            // Close navbar if it is open
            this.nav_is_active = false;
            this.setNavBarStyles();
        },
    },
    methods: {
        burgerBarHandler(){
            this.nav_is_active = !this.nav_is_active;
            this.setNavBarStyles();
        },
        setNavBarStyles(){
            if(window.innerWidth <= 650){
                if(this.nav_is_active){
                    this.nav_height = '280px';
                    this.nav_padding = '20px';
                }else{
                    this.nav_height = '0px';
                    this.nav_padding = '0 20px';
                }
            }else{
                this.nav_height = 'unset';
                this.nav_padding = 'unset';
            }
        },
        changePage(page){
            this.$router.push(page);
        },
        handleScroll() {
            // Проверяем позицию скролла и обновляем значение window_position_is_0
            this.window_position_is_0 = window.scrollY === 0;
        }
    }
}
</script>

<style scoped>
.contact_us:hover span{
    margin-left: 3px;
    font-size: 14px;
}
.contact_us span{
    margin-left: 3px;
    font-size: 0px;
    transition: 300ms;
}
.contact_us:hover{
    opacity: 1;
}
.contact_us{
    padding: 0px 10px !important;
    border-radius: 20px;
    opacity: .8;
    height: 30px;
    margin: auto 10px auto 10px !important;
}
.burger-bar img{
    width: 100%;
    height: 100%;
}
.burger-bar{
    width: 24px;
    height: 24px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    margin: auto 0;
    display: none;
}
.page_button:hover .is_active{
    opacity: 1 !important;
}
.page_button:hover{
    opacity: 1 !important;
}
.is_active{
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: -1px;
    transition: 500ms;
}
.page_button{
    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 300ms;
    position: relative;

    font-size: 13px;
    color: white;
    opacity: 0.8;
}
.header_buttons_box{
    display: flex;
    gap: 20px;
    position: relative;
    margin-left: auto;
}
.logo{
    margin: 10px 0;
    font-size: 20px;
}
header{
    background-color: rgba(0, 0, 0, 0.7);
    transition: 400ms;
    padding: 0 15%;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    z-index: 100;
}

@media screen and (max-width: 1000px) {
    header{
        padding: 0 10px;
        width: calc(100vw - 20px);
    }
}
@media screen and (max-width: 650px) {
    .page_button{
        font-size: 17px;
    }
    .contact_us{
        font-size: 13px;
        margin: auto 10px auto auto !important;
    }
    .burger-bar{
        display: block;
    }
    .header_buttons_box{
        background-color: rgba(40, 40, 40, 0.7);
        position: absolute;
        border-bottom-left-radius: 10px;
        display: grid;
        padding: 20px;
        right: 0px;
        top: 50px;
        transition: 800ms;
        overflow: hidden;
    }
}
</style>
